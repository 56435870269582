import { CollectionExpressionEntry } from "@biblioteksentralen/cordata";
import { CollectionExpressionEntryWithWork } from "@libry-content/integrations";

export const collectionExpressionEntryHasWork = (
  entry: CollectionExpressionEntry
): entry is CollectionExpressionEntryWithWork => !!entry.collection.workExpressed;

export const collectionExpressionEntryIsNotFictionalUniverse = (entry: CollectionExpressionEntryWithWork) =>
  // @ts-ignore
  entry.collection.workExpressed.collectionType !== "fictional_universe";
