import { Agent, Contributor, Manifestation, Role, Work, Year } from "@biblioteksentralen/cordata";
import { PickFromExtracted } from "@libry-content/types";
import { sort, unique } from "radash";
import { getPath } from "@libry-content/common";
import { SortableManifestation, isPerson } from "../types";
import { Translate } from "../../utils/hooks/useTranslation";

export const getMainContributor = (contributors: Contributor[] | undefined) =>
  contributors?.find(({ isMainContributor }) => isMainContributor);

export const getContributorAgents = (contributors: Contributor[] | undefined): Agent[] =>
  contributors?.map(({ agent }) => agent) ?? [];

export const addAgentHrefs = (contributors: Contributor[] | undefined) =>
  contributors?.map(({ agent, ...rest }) => ({ ...rest, agent: { ...agent, href: getAgentPath(agent) } }));

const getManifestationExpression = (work: Work, manifestation: Manifestation | undefined) =>
  manifestation
    ? (work.expressions ?? []).find(({ manifestations = [], aggregateManifestations = [] }) =>
        [...manifestations, ...aggregateManifestations].find(({ id }) => id === manifestation.id)
      )
    : undefined;

export const getAllWorkContributors = ({ contributors, expressions = [] }: Work) => [
  ...contributors,
  ...expressions.flatMap(({ contributors, manifestations = [], aggregateManifestations = [] }) => [
    ...contributors,
    ...[...manifestations, ...aggregateManifestations].flatMap(({ contributors }) => contributors),
  ]),
];

export const getRepresentativeWorkMainContributors = (
  work: Work,
  representativeManifestation: SortableManifestation | undefined
) => {
  const workMainContributor = getMainContributor(work.contributors);
  const representativeManifestationMainContributor = getMainContributor(representativeManifestation?.contributors);
  const representativeManifestationExpression = getManifestationExpression(work, representativeManifestation);
  const representativeManifestationExpressionMainContributor = getMainContributor(
    representativeManifestationExpression?.contributors
  );

  return addAgentHrefs(
    workMainContributor
      ? [workMainContributor]
      : representativeManifestationExpressionMainContributor
      ? [representativeManifestationExpressionMainContributor]
      : representativeManifestationMainContributor
      ? [representativeManifestationMainContributor]
      : undefined
  );
};

export const getRepresentativeWorkContributors = (
  work: Work,
  representativeManifestation: SortableManifestation | undefined
) => {
  const representativeManifestationExpression = getManifestationExpression(work, representativeManifestation);
  return (
    getRepresentativeWorkMainContributors(work, representativeManifestation) ??
    addAgentHrefs(
      work.contributors.length
        ? work.contributors
        : representativeManifestationExpression?.contributors?.length
        ? representativeManifestationExpression.contributors
        : representativeManifestation?.contributors
    )
  );
};

export const getAgentDatesString = (
  agent: PickFromExtracted<Agent, { type: "Person" }, "type" | "unspecifiedDate", "type">,
  t: Translate["t"]
): string | undefined => {
  if (!isPerson(agent)) return undefined;
  if (agent.birth && agent.death) {
    const includeDesignation = agent.birth.year < 0;
    const parsedBirthYear = getParsedYear({ year: agent.birth, t, includeDesignation, includeApproximation: true });
    const parsedDeathYear = getParsedYear({ year: agent.death, t, includeDesignation, includeApproximation: true });
    return `${parsedBirthYear} - ${parsedDeathYear}`;
  }
  if (agent.birth)
    return getParsedYear({
      year: agent.birth,
      t,
      includeDesignation: agent.birth.year < 0,
      includeApproximation: true,
    });
  if (agent.death)
    return getParsedYear({
      year: agent.death,
      t,
      includeDesignation: agent.death.year < 0,
      includeApproximation: true,
    });
  if (agent.activeYears)
    return `~${getParsedYear({ year: agent.activeYears, t, includeDesignation: agent.activeYears.year < 0 })}`;
  return agent.unspecifiedDate?.replace(/ ?- ?/, " - ");
};

type ParsedYearProps = {
  year: Year;
  t: Translate["t"];
  includeDesignation?: boolean;
  includeApproximation?: boolean;
};

const getParsedYear = ({ year, t, includeDesignation, includeApproximation }: ParsedYearProps) =>
  `${includeApproximation && year.isApproximate ? t("ca. ") : ""}${Math.abs(year.year)}${
    includeDesignation ? ` ${getDesignation(year.year, t)}.` : ""
  }`;

const getDesignation = (year: number, t: Translate["t"]) => (year >= 0 ? t("evt") : t("fvt"));

export const orderByMainContributor = (contributors: Contributor[]): Contributor[] =>
  sort(contributors, ({ isMainContributor }) => (isMainContributor ? 2 : 1));

const getRepresentativeContributors = (
  { contributors: workContributors }: Work,
  { contributors = [], expression }: Partial<SortableManifestation> = {}
) => unique([...workContributors, ...(expression?.contributors ?? []), ...contributors], ({ agent }) => agent.id);

export const getContributorsWithRoles = (
  work: Work,
  representativeManifestation: SortableManifestation | undefined,
  roleCodes: Role["code"][]
) =>
  getRepresentativeContributors(work, representativeManifestation)
    .filter(({ roles }) => roles.some(({ code }) => roleCodes.includes(code)))
    .map(({ roles, ...rest }) => ({ ...rest, roles: roles.filter(({ code }) => roleCodes.includes(code)) }));

export const getAgentPath = (agent: Pick<Agent, "_origin" | "id" | "type" | "name" | "identifiers">) =>
  agent._origin?.name === "promus" ? getPath(agent) : undefined;
