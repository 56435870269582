import { colors, Heading, LinkBox, ListItem, VStack } from "@biblioteksentralen/react";
import { useSearchParams } from "next/navigation";
import InternalLinkOverlay from "../../../components/InternalLinkOverlay";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { getRepresentativeWorkContributors, getRepresentativeWorkMainContributors } from "../../cordata/contributors";
import {
  findRepresentativeManifestation,
  getFormattedRepresentativeManifestationTitle,
  getRepresentativeManifestation,
  WorkToBeRepresented,
} from "../../cordata/manifestations";
import { getParallelTitles } from "../../cordata/titles";
import { Collections } from "../Collections";
import { DocumentTypes } from "../DocumentTypes";
import { TruncatedList } from "../TruncatedList";
import { WorkCoverImage } from "../WorkCoverImage";
import { ReactNode, useEffect, useRef } from "react";
import { formatIsbdTitle, isMovingImageWork } from "@biblioteksentralen/cordata";
import { useWorkLangAttribute } from "../../../utils/hooks/useWorkLangAttribute";
import {
  formatLanguagesList,
  getAvailableWorkLanguages,
  getLanguagesSignature,
  toCordataLanguageCode,
} from "@libry-content/integrations";

type WorkPreviewProps = {
  work: WorkToBeRepresented;
  headingSize?: "h2" | "h3";
  children?: ReactNode;
  autoFocus?: boolean;
};

/**
 * Return language-code in Iso 639-2 format. IE. "eng", "nob", "swe".
 */
export const useWorkUrlLanguage = (work: WorkToBeRepresented) => {
  const { lang } = useTranslation();
  const selectedLang =
    useSearchParams()?.get("språk") ?? // bruker språk fra urlparam hvis det finnes, da har bruker valgt språk selv
    toCordataLanguageCode(lang) ??
    "nob";

  if (work.representativeLanguages) return work.representativeLanguages;

  const representativeManifestationLanguages = work.expressions?.find(({ manifestations }) =>
    manifestations?.find(({ id }) => id === work.representativeManifestationId)
  )?.languages;

  if (representativeManifestationLanguages && representativeManifestationLanguages.length > 0)
    return getLanguagesSignature(representativeManifestationLanguages);

  const haveExpressionWithSiteLanguage = (work.expressions ?? []).some(
    ({ languages = [] }) => languages.length === 1 && languages[0]?.code === selectedLang
  );

  if (haveExpressionWithSiteLanguage) return selectedLang;

  return getLanguagesSignature(getAvailableWorkLanguages(work)[0] ?? []);
};

export const usePreferredWorkPreviewData = (work: WorkToBeRepresented) => {
  const representativeLanguages = useWorkUrlLanguage(work);
  const representativeManifestation = getRepresentativeManifestation({ ...work, representativeLanguages });
  const path = getPath(work);
  const formattedTitle =
    getFormattedRepresentativeManifestationTitle(work, representativeManifestation) ?? formatIsbdTitle(work);
  const mainContributor = getRepresentativeWorkMainContributors(work, representativeManifestation)?.[0];
  const director = getRepresentativeWorkContributors(work, representativeManifestation)?.find((contributor) =>
    contributor.roles.find((role) => role.code === "regissør")
  );
  return {
    path,
    formattedTitle,
    representativeManifestation,
    mainContributor: isMovingImageWork(work) ? director : mainContributor,
  };
};

const WorkPreview = ({ work, headingSize = "h3", children, autoFocus }: WorkPreviewProps) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const { path, formattedTitle, mainContributor } = usePreferredWorkPreviewData(work);
  useEffect(() => {
    autoFocus && ref.current?.focus();
  }, [autoFocus]);

  const representativeLanguages = useWorkUrlLanguage(work);
  const representativeManifestation = getRepresentativeManifestation({ ...work, representativeLanguages });

  const representativeManifestationIsbn = findRepresentativeManifestation(work)?.isbn[0];

  const pathWithRepresentation = representativeManifestationIsbn
    ? `${path}?isbn="${representativeManifestationIsbn}"`
    : representativeLanguages
    ? `${path}?language="${representativeLanguages}"`
    : path;

  const parallelTitles = getParallelTitles(representativeManifestation);
  const languages = getAvailableWorkLanguages(work, representativeLanguages);
  const formattedLanguages = languages.map((languagesList, index) => ({
    name: formatLanguagesList(languagesList, { capitalizeFirstLetter: index == 0 }),
  }));
  const langAttribute = useWorkLangAttribute(work);

  return (
    <LinkBox
      as={ListItem}
      display="flex"
      alignItems="flex-end"
      role="group"
      lineHeight="1.2"
      height="100%"
      minHeight="11.5rem"
      isolation="isolate"
    >
      <WorkCoverImage
        work={work}
        representativeManifestation={representativeManifestation}
        maxWidth="7rem"
        minWidth="7rem"
        borderRadius="lg"
        showFavoriteButton
      />
      <VStack marginLeft="0.5rem" alignItems="flex-start" spacing="0.25rem">
        <DocumentTypes work={work} />
        <InternalLinkOverlay
          id={`bibbi-work:${work.id}`}
          href={pathWithRepresentation}
          _groupHover={{ textDecoration: "underline" }}
          ref={ref}
        >
          <Heading
            sx={{ WebkitBoxOrient: "vertical", WebkitLineClamp: 4 }}
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth="100%"
            as={headingSize}
            fontSize="xl"
            display="-webkit-box"
            flexDirection="column"
            wordBreak="break-word"
            lang={langAttribute}
          >
            {[formattedTitle, ...parallelTitles].join(" | ")}
          </Heading>
        </InternalLinkOverlay>
        {mainContributor && (
          <TruncatedList items={[mainContributor.agent]} max={2} _hover={{ textDecoration: "none !important" }} />
        )}
        {formattedLanguages.length && (
          <TruncatedList items={formattedLanguages} max={3} fontSize="sm" color={colors.grey60} />
        )}
        <Collections fontSize="sm" collections={representativeManifestation?.expression?.collections} />
        {children}
      </VStack>
    </LinkBox>
  );
};

export default WorkPreview;
